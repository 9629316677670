import { useStore } from "@nanostores/react";
import { Fragment, useEffect, useRef } from "react";

import { Advert, updateAdvertory } from "@vgno/adverts";
import { Teaser } from "@vgno/teasers/Teaser";
import SkeletonFeed from "@vgno/teasers/Skeleton";
import VideoCarousel from "@vgno/widgets/VideoCarousel";
import VideoList from "@vgno/widgets/VideoList";

import { $teaserStore, fetchTeasers } from "../utils/fetchFront";

import { VGLiveTeaser } from "./VGLiveTeaser/VGLiveTeaser";

import type { Article } from "@vgno/article";
import type { AdConfig } from "@vgno/astro/@types";
import type { Video } from "vg";

import articleTeaserStyles from "./ArticleTeaser/ArticleTeaser.module.css";

interface Props {
  advertsInsertionRules?: AdConfig["insertionRules"];
  isIOS?: boolean;
  upcomingBroadcasts?: Video[];
  verticalVideos?: (Video & { url: string })[];
}

export const Feed = ({
  advertsInsertionRules,
  isIOS,
  upcomingBroadcasts,
  verticalVideos,
}: Props) => {
  const sentinel = useRef<HTMLDivElement | null>(null);
  const { bundles, isFinished, isLoading } = useStore($teaserStore);

  // Intersection Observer for loading more teasers
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          fetchTeasers();
        }
      },
      {
        root: null,
        rootMargin: "100%",
        threshold: 0.1,
      },
    );

    const currentSentinel = sentinel.current;
    if (currentSentinel) {
      observer.observe(currentSentinel);
    }

    return () => {
      if (currentSentinel) {
        observer.unobserve(currentSentinel);
      }
    };
  }, [bundles.length]);

  useEffect(() => {
    const sectionFeed = document.querySelector('section[role="feed"]');

    if (sectionFeed) {
      sectionFeed.setAttribute("aria-busy", isLoading ? "true" : "false");
    }

    if (!isLoading) {
      updateAdvertory({}, { contextSelector: "#feed" });
    }
  }, [isLoading]);

  const widgets = {
    5: verticalVideos && (
      <VideoCarousel
        className="widget p-m background-secondary shadow-small"
        format="vertical"
        isIOS={isIOS}
        title="Korte videoer"
        videos={verticalVideos}
      />
    ),
    8: upcomingBroadcasts && (
      <VideoList
        className="widget"
        data={upcomingBroadcasts}
        data-track-element-type="Upcoming Broadcasts"
        data-track-id="upcoming-broadcasts"
        data-track-impression
        data-track-name="Upcoming Broadcasts"
        title="Kommende direktesendinger"
        vgtvUrl="https://tv.vg.no/kategori/3/sport"
      />
    ),
  };

  return (
    <section data-track-curate-context="sport" style={{ display: "contents" }}>
      {bundles.map((bundle, bundleIndex) => {
        if (bundle.length === 0) return undefined;

        const advert = advertsInsertionRules?.find(
          (advert) => advert.index === bundleIndex,
        );

        return (
          <Fragment key={bundle[0].id}>
            {advert && (
              <Advert
                placementId={advert.placementId}
                subtype={advert.subtype}
              />
            )}
            {widgets[bundleIndex]}
            {bundle.map((teaser, teaserIndex) => {
              const teaserSize = getTeaserSize(
                teaser,
                bundleIndex,
                teaserIndex,
              );

              if (isVgliveTeaser(teaser)) {
                return (
                  <VGLiveTeaser
                    customProperties={teaser.customProperties}
                    imageAsset={teaser.promotionContent?.imageAsset}
                    key={teaser.id}
                    loading={bundleIndex >= 1 ? "lazy" : "eager"}
                    size="medium" // Currently only medium size is supported
                    title={teaser.title.value}
                    url={teaser.customProperties.vgliveLink}
                  />
                );
              }

              const video = teaser.components?.find(
                (component) => component.type === "video",
              );

              const isVgtvVideoTeaser =
                teaser.contentType === "video" &&
                video &&
                video.type === "video";

              if (isVgtvVideoTeaser) {
                const todayStart = new Date();
                todayStart.setHours(0, 0, 0, 0);
                const todayEnd = new Date();
                todayEnd.setHours(23, 59, 59, 999);

                const isTodayFlight = video?.videoAsset?.flightTimes?.start
                  ? video.videoAsset.flightTimes.start * 1000 >=
                      todayStart.getTime() &&
                    video.videoAsset.flightTimes.start * 1000 <=
                      todayEnd.getTime()
                  : false;

                if (
                  teaser.contentType === "video" &&
                  video?.type === "video" &&
                  video.videoAsset?.metadata?.contentType === "liveSports" &&
                  !isTodayFlight
                ) {
                  return;
                }
              }

              const isOverlayVariant =
                teaserSize !== "small" && bundleIndex % 2 === 0;

              return (
                <Teaser
                  {...teaser}
                  key={teaser.id}
                  tracking={{
                    bundlePosition: teaserIndex + 1,
                    primaryPosition: bundleIndex + 1,
                  }}
                  fetchPriority={bundleIndex === 0 ? "high" : "low"}
                  loading={bundleIndex >= 1 ? "lazy" : "eager"}
                  useOverlay={isOverlayVariant && !isVgtvVideoTeaser}
                  size={teaserSize}
                  styles={{
                    link: articleTeaserStyles.link,
                    ...(isOverlayVariant
                      ? { small: articleTeaserStyles.small }
                      : {}),
                  }}
                />
              );
            })}
          </Fragment>
        );
      })}
      {!isFinished && (
        <div ref={sentinel}>
          <SkeletonFeed />
        </div>
      )}
    </section>
  );
};

function getTeaserSize(
  teaser: Article["teaser"],
  bundleIndex: number,
  teaserIndex: number,
) {
  return teaser.characteristics.hotness >= 60 ||
    (bundleIndex === 0 && teaserIndex === 0)
    ? "medium"
    : "small";
}

function isVgliveTeaser(teaser: Article["teaser"]) {
  return (
    teaser.contentType === "event" &&
    Boolean(teaser.customProperties.vgliveLink)
  );
}
